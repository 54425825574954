import { useCallback, useState } from 'react';
import { BoxProps, Image, Text, VStack } from '@chakra-ui/react';
import { MentionsModal } from 'components/MentionsModal';
import { Utilisateur } from 'types';

import { Panel } from 'components/Panel';

export type Props = {
  utilisateur: Utilisateur;
  image: string;
  description: string;
  description_light: string;
} & BoxProps;

export const SimulatorPanelCustomModal = ({
  utilisateur,
  image,
  description,
  description_light,
  ...props
}: Props) => {
  const [mentionsModalOpen, setMentionsModalOpen] = useState<boolean>(false);

  // Click onto "Mon profil" .
  const onMentionsClick = useCallback(() => {
    setMentionsModalOpen(true);
  }, []);

  return (
    <Panel
      as="a"
      _hover={{ backgroundColor: 'gray.50', cursor: 'pointer' }}
      _active={{ backgroundColor: 'gray.100' }}
      alignItems="flex-end"
      display="flex"
      height="100px"
      pb={7}
      pr={5}
      position="relative"
      target="_blank"
      width={{
        base: '100%',
        md: '260px',
      }}
      float={'left'}
      margin={{ base: '5px' }}
      onClick={onMentionsClick}
      {...props}
    >
      <Image
        fallbackSrc={'/placeholder-image.png'}
        fit={'scale-down'}
        alt="Simulateur de crédit"
        top={3}
        height={90}
        left={4}
        position="absolute"
        src={image}
        width={90}
      />
      <VStack
        position="absolute"
        top={6}
        right={4}
        alignItems="flex-end"
        maxWidth="60%"
        ml="auto"
        spacing={2}
        textAlign="right"
      >
        <Text fontSize="xs" fontWeight="medium">
          {description_light}
        </Text>
        <Text as="h3" fontWeight="bold">
          {description}
        </Text>
        <MentionsModal
          utilisateur={utilisateur}
          isOpen={mentionsModalOpen}
          onClose={() => setMentionsModalOpen(false)}
        />
      </VStack>
    </Panel>
  );
};
