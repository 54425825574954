import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LoginPageRoute } from 'pages/Login';
import { HomePageRoute } from 'pages/Home';
import { ClientPageRoute } from 'pages/Client';
import { StatPageRoute } from 'pages/Statistique';
import { AuthSsoRoute } from 'pages/AuthSso';
import { PasswordRecoveryRoute } from 'pages/PasswordRecovery';
import { FilePageRoute } from 'pages/File';
import { BannerEnvIndicator } from 'components/BannerEnvIndicator';
import { ScrollToTop } from 'components/ScrollToTop';
import { theme } from 'theme';
import { changeFavicon } from 'utils/marque';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { AuthContextProvider, MeContextProvider } from 'auth/AuthContextProvider';

import ProtectedRoute from 'auth/ProtectedRoute';

Sentry.init({
  dsn: 'https://8e380e16564f4cc390d5e76d94f72300@o413777.ingest.sentry.io/5301821',
  environment: window.location.hostname ? window.location.hostname : 'inconnu',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

// QueryClient creation
const queryClient = new QueryClient();
changeFavicon();
// Basic App routing
const App = () => (
  <Router>
    <ScrollToTop />
    <Switch>
      <Route path="/login">
        <LoginPageRoute />
      </Route>
      <Route path="/auth">
        <AuthSsoRoute />
      </Route>
      <Route path="/password-recovery">
        <PasswordRecoveryRoute />
      </Route>
      <ProtectedRoute path="/dossiers/:id">
        <FilePageRoute ressources="dossiers" />
      </ProtectedRoute>
      <ProtectedRoute path="/contacts/:id">
        <FilePageRoute ressources="contacts" />
      </ProtectedRoute>
      <ProtectedRoute path="/clients/:id">
        <ClientPageRoute />
      </ProtectedRoute>
      <ProtectedRoute path="/statistique">
        <StatPageRoute />
      </ProtectedRoute>
      <ProtectedRoute path="/home">
        <HomePageRoute />
      </ProtectedRoute>
      <ProtectedRoute path="/">
        <HomePageRoute />
      </ProtectedRoute>
    </Switch>
  </Router>
);

// Render on DOM
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <AuthContextProvider>
          <MeContextProvider>
            <BannerEnvIndicator />
            <App />
          </MeContextProvider>
        </AuthContextProvider>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
