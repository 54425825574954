// Voir ici pour plus d'info sur le theme: https://chakra-ui.com/docs/theming/theme
import { extendTheme } from '@chakra-ui/react';
import { Styles } from '@chakra-ui/theme-tools';
import { rgba } from 'polished';

import { checkmarkIconDataURI } from 'components/Icons/Checkmark';
import { crossIconDataURI } from 'components/Icons/Cross';
import { getStyles as getDatePickerStyles } from 'components/DatePicker/styles';
import { getStyles as getCarouselStyles } from 'components/CarouselPanel/styles';

// Breakpoints par default, non modifiés mais affichés ici pour faciliter leur modification.
// https://chakra-ui.com/docs/theming/theme#breakpoints
const breakpoints = {
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280px
  xxl: '96em', // 1536px
};

// Couleur "normales"
const defaultColors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#ffffff',
  gray: {
    50: '#F7F6F4',
  },
  green: {
    500: '#17CF97',
  },
  teal: {
    500: '#00A0A2',
    900: '#003440',
  },
  yellow: {
    500: '#FFCD03',
  },
  orange: {
    500: '#FF9A00',
  },
  red: {
    500: '#FF5050',
  },
};

// Extension avec les couleurs ayant de l'opacité
const colors = {
  ...defaultColors,
  teal: {
    ...defaultColors.teal,
    100: rgba(defaultColors.teal[500], 0.1),
    200: rgba(defaultColors.teal[500], 0.2),
  },
  gray: {
    ...defaultColors.gray,
    100: rgba(defaultColors.teal[900], 0.1),
    200: rgba(defaultColors.teal[900], 0.4),
    300: rgba(defaultColors.teal[900], 0.5),
    400: rgba(defaultColors.teal[900], 0.6),
    500: rgba(defaultColors.black, 0.6),
  },
  orange: {
    ...defaultColors.orange,
    50: rgba(defaultColors.orange[500], 0.15),
    300: rgba(defaultColors.orange[500], 0.5),
  },
};

const fonts = {
  heading: 'Montserrat',
  body: 'Montserrat',
};

const fontSizes = {
  xxs: '10px',
  xs: '11px',
  sm: '12px',
  md: '13px',
  lg: '14px',
  xl: '15px',
  '2xl': '16px',
  '3xl': '17px',
  '4xl': '18px',
  '5xl': '20px',
  '6xl': '25px',
  '7xl': '36px',
};

const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const lineHeights = {
  normal: 'normal',
  none: 1,
  shorter: 1.25,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: '2',
  '3': '.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '7': '1.75rem',
  '8': '2rem',
  '9': '2.25rem',
  '10': '2.5rem',
};

const radii = {
  none: '0',
  sm: '3px',
  base: '5px',
  md: '10px',
  lg: '20px',
  xl: '45px',
};

const shadows = {
  xs: `0 0 20px 0 ${rgba(colors.black, 0.05)}`,
  outline: `0 0 0 2px ${rgba(defaultColors.teal[500], 0.6)}`,
  // Acting like borders on buttons
  gray: `0 0 0 2px ${colors.gray[500]}`,
  teal: `0 0 0 2px ${colors.teal[500]}`,
  orange: `0 0 0 2px ${colors.yellow[500]}`,
};

const space = {
  0: '0',
  1: '0.0625rem', // 1px
  2: '0.125rem', // 2px
  3: '0.3125rem', // 5px
  4: '0.625rem', // 10px
  5: '0.9375rem', // 15px
  6: '1.25rem', // 20px
  7: '1.5625rem', // 25px
  8: '1.875rem', // 30px
  9: '2.1875rem', // 35px
  10: '2.5rem', // 40px
  10.5: '2.8125rem', // 45px - TODO: Fix me!
  11: '3.125rem', // 50px
  12: '3.75rem', // 60px
  13: '4.375rem', // 70px
  14: '5rem', // 80px
  15: '5.625rem', // 90px
  16: '6.25rem', // 100px
  17: '7.5rem', // 120px
  18: '9.375rem', // 150px
  19: '12.5rem', // 200px
  20: '15.625rem', // 250px
};

const sizes = {
  ...space,
  sidebar: '260px',
  headerMobile: '65px',
  headerDesktop: '85px',
  footerMobile: '85px',
  footerDesktop: '85px',
  full: '100%',
};

// Overrides des styles de components fournis par défault
// https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/components
const components = {
  Accordion: {
    baseStyle: {
      container: {
        borderColor: 'gray.100',
        _first: {
          borderTopWidth: 0,
        },
        _last: {
          borderBottomWidth: 0,
        },
      },
      button: {
        fontSize: { base: 'xl', lg: '5xl' },
        fontWeight: 'semibold',
        py: { base: 6, lg: 8 },
        px: { base: 6, lg: 10 },
        _hover: {
          bg: 'inherit',
          color: 'teal.500',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
      panel: {
        pt: 0,
        pb: { base: 6, lg: 8 },
        px: { base: 6, lg: 10 },
      },
      icon: {
        fontSize: '7xl',
      },
    },
  },
  Avatar: {
    baseStyle: {
      container: {
        bg: 'teal.500',
        color: 'white',
      },
    },
    sizes: {
      md: {
        container: {
          width: 45,
          height: 45,
        },
      },
      lg: {
        container: {
          width: 50,
          height: 50,
        },
      },
    },
  },
  Button: {
    baseStyle: {
      borderRadius: 0,
      fontWeight: 'semibold',
    },
    sizes: {
      md: {
        fontSize: 'lg',
        height: 'auto',
        minW: 'auto',
        px: 0,
      },
    },
    variants: {
      link: {
        color: 'teal.900',
      },
    },
  },
  Checkbox: {
    baseStyle: {
      control: {
        bg: 'white',
        border: '1px solid',
      },
      label: {
        color: 'gray.500',
        fontWeight: 'medium',
        _checked: {
          color: 'black',
        },
      },
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6,
        },
        label: {
          fontSize: 'sm',
          marginInlineStart: 4,
        },
      },
    },
  },
  Divider: {
    baseStyle: {
      borderColor: 'teal.900',
      opacity: 0.1,
    },
  },
  FormLabel: {
    baseStyle: {
      color: 'teal.500',
      fontSize: 'sm',
      fontWeight: 'bold',
      mb: 0,
      mr: 0,
    },
  },
  Input: {
    baseStyle: {
      field: {
        minH: 45,
        _placeholder: {
          color: 'gray.200',
          fontWeight: 'normal',
        },
      },
    },
    sizes: {
      md: {
        field: {
          borderRadius: 'sm',
          fontSize: 'sm',
          h: 45,
          px: 4,
        },
      },
    },
    variants: {
      outline: {
        field: {
          bg: 'white',
          borderColor: 'gray.200',
          fontWeight: 'semibold',
          _hover: {
            borderColor: 'gray.500',
          },
          _focus: {
            borderColor: 'teal.500',
            boxShadow: 'none',
          },
        },
      },
    },
  },
  Modal: {
    baseStyle: {
      overlay: {
        bg: rgba(colors.black, 0.5),
      },
      dialogContainer: {
        alignItems: 'center',
      },
      dialog: {
        boxShadow: 'xs',
        mx: 6,
      },
      header: {
        fontSize: { base: 'xl', lg: '2xl' },
        fontWeight: 'bold',
        pb: { base: 4, lg: 6 },
        pt: 8,
        px: { base: 6, lg: 8 },
      },
      body: {
        pb: { base: 0, lg: 6 },
        pt: 0,
        px: { base: 6, lg: 8 },
      },
      footer: {
        borderRadius: 'md',
        boxShadow: 'xs',
        px: { base: 6, lg: 8 },
        py: 8,
      },
      closeButton: {
        color: 'gray.300',
        height: 8,
        insetEnd: { base: 3, lg: '22.5px' },
        top: { base: 3, lg: '27.5px' },
        width: 8,
        '& svg': { width: 5, height: 5 },
      },
    },
  },
  Popover: {
    parts: ['content'], // This fixes an issue with TS, but it looks wrong...
    baseStyle: {
      content: {
        borderRadius: 'base',
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
  Radio: {
    baseStyle: {
      control: {
        _checked: {
          _before: {
            bg: 'transparent',
            backgroundImage: `url('${checkmarkIconDataURI}')`,
            backgroundSize: '100% 100%',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            w: 4,
            h: 4,
          },
        },
      },
      label: {
        color: 'gray.500',
        fontWeight: 'medium',
        _checked: {
          color: 'black',
        },
      },
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6,
        },
        label: {
          fontSize: 'sm',
          marginInlineStart: 4,
        },
      },
    },
  },
  Select: {
    baseStyle: {
      field: {
        minH: 45,
        _placeholder: {
          color: 'gray.200',
        },
      },
      icon: {
        color: 'gray.200',
        insetEnd: 4,
        w: 'auto',
        fontSize: '0.75rem',
      },
    },
    sizes: {
      md: {
        field: {
          borderRadius: 'sm',
          h: 45,
          px: 4,
        },
      },
    },
    variants: {
      outline: {
        field: {
          bg: 'white',
          borderColor: 'gray.200',
          fontWeight: 'semibold',
          _hover: {
            borderColor: 'gray.500',
          },
          _focus: {
            borderColor: 'teal.500',
            boxShadow: 'none',
          },
        },
      },
    },
  },
  Switch: {
    baseStyle: {
      container: {
        w: 10,
        h: 6,
      },
      track: {
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'teal.900',
        borderRadius: 'md',
        p: 0,
        _focus: {
          boxShadow: 'gray',
        },
        _checked: {
          bg: rgba(colors.teal[500], 0.3),
          borderColor: 'teal.500',
          _focus: {
            boxShadow: 'teal',
          },
        },
      },
      thumb: {
        bg: 'teal.900',
        backgroundImage: `url('${crossIconDataURI}')`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '8px',
        _checked: {
          bg: 'teal.500',
          backgroundImage: `url('${checkmarkIconDataURI}')`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '10px',
        },
      },
    },
    sizes: {
      md: {
        container: {
          '--slider-track-width': '38px',
          '--slider-track-height': '18px',
        },
      },
    },
  },
  Tabs: {
    baseStyle: {
      tabpanels: {
        bg: 'white',
      },
      tabpanel: {
        p: 0,
      },
      tab: {
        color: 'teal.900',
        opacity: 0.5,
        _selected: {
          bg: 'white',
          color: 'teal.500',
          opacity: 1,
        },
      },
    },
    sizes: {
      md: {
        tab: {
          fontSize: 'sm',
          px: 5,
          py: 4,
        },
      },
    },
    variants: {
      'enclosed-colored': {
        tabpanels: {
          borderBottomRightRadius: 'md',
          borderBottomLeftRadius: 'md',
          borderTopRightRadius: 'md',
          boxShadow: 'xs',
        },
        tablist: {
          alignItems: 'flex-end',
          borderBottom: 0,
          h: 10,
          mb: 0,
        },
        tab: {
          bg: 'white',
          border: 0,
          borderTopLeftRadius: 'md',
          borderTopRightRadius: 'md',
          h: 9,
          mb: 0,
          _notLast: {
            marginInlineEnd: 4,
          },
          _selected: {
            h: 11,
            color: 'teal.500',
          },
        },
      },
      line: {
        tablist: {
          borderBottom: 0,
        },
        tab: {
          h: 11,
          _notLast: {
            marginInlineEnd: 4,
          },
          _active: {
            bg: 'white',
          },
          _focus: {
            boxShadow: 'none',
          },
          _selected: {
            color: 'teal.500',
          },
        },
      },
    },
  },
  Tag: {
    sizes: {
      md: {
        container: {
          borderRadius: 'base',
          fontSize: 'md',
          minH: 7,
          minW: 'auto',
          px: 4,
        },
      },
    },
  },
  Textarea: {
    baseStyle: {
      display: 'block',
      minHeight: 150,
      py: 4,
    },
    variants: {
      outline: {
        bg: 'white',
        borderColor: 'gray.200',
        _hover: {
          borderColor: 'gray.500',
        },
        _focus: {
          borderColor: 'teal.500',
          boxShadow: 'none',
        },
      },
    },
    sizes: {
      sm: {
        borderRadius: 'sm',
        px: 4,
      },
      md: {
        borderRadius: 'sm',
        px: 4,
      },
    },
  },
};

// Overrides des styles globaux
// (https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/styles.ts)
const styles: Styles = {
  global: (props) => ({
    '::selection': {
      color: 'white',
      bg: 'teal.900',
    },
    body: {
      bg: 'gray.50',
      color: 'teal.900',
    },
    figure: {
      marginBlockEnd: 0,
      marginInlineEnd: 0,
    },
    ...getDatePickerStyles(props),
    ...getCarouselStyles(props),
  }),
};

export const theme = extendTheme({
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  shadows,
  space,
  sizes,
  components,
  styles,
});
