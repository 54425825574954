import { useCallback, useState } from 'react';
import { auth } from 'auth/AuthContextProvider';
import { Utilisateur } from 'types';
import {
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Tooltip,
} from '@chakra-ui/react';
import { Button } from 'components/Button';
import { InputField } from 'utils/form';
import { InfoIcon } from '@chakra-ui/icons';

export type Props = {
  utilisateur: Utilisateur;
} & Omit<ModalProps, 'children'>;

export const MentionsModal = ({ utilisateur, ...props }: Props) => {
  return (
    <Modal {...props} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW="50%">
        <ModalHeader color={'red'}>
          <Tooltip label="Le montant total du crédit doit être égal à 50 000 euros ou à un multiple de cette somme (dans la limite de 500 000€). La durée de remboursement doit être égale à cinq ans ou à un multiple de cette durée (dans la limite de 30 ans)">
            <Center w={'80%'} ml="10%">
              <InfoIcon boxSize={8} mr="20px" /> Attention : La règlementation impose des conditions
              en terme de montant et de durée
            </Center>
          </Tooltip>
        </ModalHeader>
        <ModalBody>
          <div
            ref={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (typeof window.mec?.renderWidget === 'function') {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.mec.renderWidget();
              }
            }}
            className="mec--widget-app"
            data-integration="default"
            data-label="wimontant / mois pour wiyear ans"
            data-calculette-mensualite="true"
            data-calculette-notaire="false"
            data-calculette-emprunt="false"
            data-calculette-ptz="false"
            data-wimodal-btn-mensualite="true"
            data-duree-pret="25"
            data-type-logement="LOGEMENT_ANCIEN"
            data-montant-bien="501000.00"
            data-rfr="45000"
            data-wimodal-btn-class="mec-btn mec-btn mec-btn--secondary mec-btn--md"
            data-mentions-legales-text="Mentions légales"
            data-show-text-mentions-legales-on-button="true"
            data-show-copy-mentions-legales-on-button="true"
            data-type-calcul="mensualite"
            data-cle={utilisateur?.organisation?.cle}
            data-logo="https://www.monemprunt.com/static/logo-2f1d95c77461634bd37c3ff2b40fe368.svg"
            data-target-cta="_blank"
          ></div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
